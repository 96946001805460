import React from 'react';
import PageTitle from '../components/pagetitle/PageTitle';


BlogDetails01.propTypes = {
    
};

function BlogDetails01(props) {
    return (
        <div>

            <PageTitle sub='Blog' title='Dasar Privasi' />
            
            <section className="tf-blog-detail">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="detail-inner">
                                
                                <div className="content-inner" style={{textAlign:"justify"}}>
                                    <h6 className="title">1. Polisi Privasi</h6>
                                    <p >Akta Perlindungan Data Peribadi atau dikenali sebagai Personal Data Protection Act (PDPA) 2010 dijadikan garis panduan oleh Al-Jariyah bagi menyusun proses Maklumat Peribadi untuk semak transaksi. Akta ini juga digunakan oleh sema syarikat berdaftar di Malaysia berkaitan hak pengguna ke atas semua Maklumat Peribadi yang diberi kepada syarikat terbabit bagi memproses maklumat dengan tujuan memudahkan urusan perkhidmatan. Akta ini juga mensyaratkan syarikat untuk mendapat persetujuan daripada pengguna dalam urusan memproses Maklumat Peribadi.</p><br/>
                                    <p>Polisi privasi Al-Jariyah menerangkan bagaimana Al-Jariyah menggunakan dan melindungi Maklumat Peribadi pengguna yang diperolehi di dalam Platform Al-Jariyah untuk memudahkan pengguna melakukan transaksi. Polisi Privasi ini juga akan menerangkan apakah Maklumat Peribadi yang dikumpul, bagaimana maklumat yang dikumpul itu digunakan, pihak yang akan terlibat dalam perkongsian maklumat, bagaimana pengguna boleh ubahsuai Maklumat Peribadi yang diselenggara oleh Al-Jariyah, dan segala perubahan yang akan dibuat oleh Al-Jariyah dari semasa ke semasa.</p>
                                </div>
                                
                                <div className="content-inner" style={{textAlign:"justify"}}>
                                    <h6 className="title">2. Pengumpulan dan Penggunaan Maklumat</h6>
                                    <p>Al-Jariyah akan mengumpul dan menyelenggara maklumat peribadi yang diberikan oleh pengguna mengikut garis panduan PDPA 2010.</p><br/>
                                    <p>Apabila pengguna berinteraksi dengan Platform Al-Jariyah, Al-Jariyah akan mengumpul dan menerima maklumat peribadi pengguna seperti nama, nombor telefon, e-mel dan alamat yang didaftarkan dalam platform Al-Jariyah bagi tujuan memberikan perkhidmatan.</p><br/>
                                    <p>Dalam usaha memahami dan menyediakan perkhidmatan yang lebih baik kepada pengguna, Al-Jariyah akan melakukan kajian ke atas pengguna berdasarkan maklumat demografi, minat dan kelakuan berdasarkan maklumat dan data peribadi yang diberikan oleh pengguna. Kajian ini mungkin akan dikonsgi dengan pihak ketiga untuk menunjukkan corak penggunaan Platform Al-Jariyah di sesuatu institusi tanpa mendedahkan maklumat peribadi pengguna.</p>
                                </div>
                                
                                
                            </div>                        
                        </div>                                     
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default BlogDetails01;