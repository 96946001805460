import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import './styles.scss'

PageTitle.propTypes = {
    
};

function PageTitle({ sub1, sub2, title, none }) {
    const navigate = useNavigate();

    const goBack = (e) => {
        e.preventDefault(); // Prevent the default link behavior
        navigate(-1); // Go back to the previous page
    };
    
    return (
        <section className="tf-page-title">    
            <div className="tf-container">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="breadcrumbs" >
                            <li><Link to="/">Home</Link></li>
                            <li className={none}><Link to="/senarai-institusi">{sub1}</Link></li>
                            <li className={none}>
                                <Link to="/" onClick={goBack}>
                                    {sub2}
                                </Link>
                            </li>
                            <li>{title}</li>
                        </ul>
                        <h4 className="page-title-heading">{title}</h4>
                    </div>
                </div>
            </div>                    
        </section>
    );
}

export default PageTitle;