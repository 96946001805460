import BeritaAktiviti from "./BeritaAktiviti";
import Terma from "./terma";
import Penafian from "./penafian";
import Privasi from "./privasi";
import BeritaAktivitiDetails from "./BeritaAktivitiDetails";
import Contact from "./Contact";
import SenaraiInstitusi from "./SenaraiInstitusi";
import MengenaiAJ from "./MengenaiAJ";
import Home01 from "./Home01";
import InstitusiDetaisl from "./InstitusiDetaisl";
import Maulid from "./maulid";
import MaklumatKempen from "./MaklumatKempen";
import Test from "./TestMaklumatKempen";
import Receipt from "./Receipt";
import Kelas from "./kelasngaji";
import Sabah from "./sabah";
import IOI from "./ioimusolla";




const routes = [
  { path: '/', component: <Home01 />},
  { path: '/senarai-institusi', component: <SenaraiInstitusi />},
  { path: '/institusi/:slug', component: <InstitusiDetaisl />},
  { path: '/mengenai-aljariyah', component: <MengenaiAJ />},
  { path: '/berita-aktiviti', component: <BeritaAktiviti />},
  { path: '/terma', component: <Terma />},
  { path: '/penafian', component: <Penafian />},
  { path: '/privasi', component: <Privasi />},
  { path: '/A-COLLABORATION-TO-EMPOWER-RELIGIOUS-INSTITUTIONS', component: <BeritaAktivitiDetails />},
  { path: '/institusi/hebahan/:slug', component: <Maulid />},
  { path: '/institusi/:slug/kempen/:slug', component: <MaklumatKempen />},
  { path: '/institusi/kempen', component: <Test />},
  { path: '/bayaran/resit', component: <Receipt  />},
  { path: '/institusi/Masjid-Bandar-Sg-Long/KELAS-MENGAJI-DEWASA', component: <Kelas />},
  { path: '/institusi/Masjid-Sabah/Hebah-Sembulan-Mosque', component: <Sabah />},
  { path: '/institusi/Masjid-IOI/Hebah-IOI-Mosque', component: <IOI />},
  { path: '/contact', component: <Contact />}


]

export default routes;