import React , {useState} from 'react';
import PropTypes from 'prop-types';

import { Navigation, Scrollbar, A11y , Pagination   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import CardModal from '../layouts/CardModal';

import { Link } from 'react-router-dom';

PerkhidmatanKami.propTypes = {
    data : PropTypes.array,
};

function PerkhidmatanKami(props) {

    const [modalShow, setModalShow] = useState(false);

    const {data} = props;
    return (
        <section className="tf-section tf-live-auction visible-sw">
            <div className="tf-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-heading mb40 wow fadeInUp">
                            <h4 className="heading">Perkhidmatan Kami</h4>
                        </div>
                    </div>
                    <div className="col-md-12 wow fadeInUp">

                    <Swiper
                        modules={[Navigation,  Scrollbar, A11y , Pagination ]}
                        spaceBetween={30}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                },
                            767: {
                                slidesPerView: 2,
                            },
                            991: {
                                slidesPerView: 3,
                            },
                            1300: {
                                slidesPerView: 4,
                            },
                        }}
                        className="live-auction visible"
                        navigation
                        loop= {false}
                        pagination={{
                            clickable: true,
                            style: {
                                bottom:"-80px",
                            },
                            
                          }}
                        
                        style={{height:"460px"}}
                    >
                    {
                        data.map(idx => (
                            <SwiperSlide key={idx.id} style={{ height: '350px' }}>
                                <div className="slider-item" style={{ display: 'flex', flexWrap: 'wrap', height: '100%', cursor: 'default', pointerEvents: 'none', }}>
                                    <div className="sc-product style1" style={{ flex: '1 0 25%', height: '100%' }}>
                                        <div className="tf-product-category">
                                            <img src={idx.img} alt="AlJariyah" />
                                            <div className="category">
                                                <Link to="#">{idx.title}</Link>
                                            </div>
                                        </div>
                                        <div className="bottom" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                            <div className="details-product" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                <div className="author" style={{ flex: '1' }}>
                                                    <div className="content">
                                                        <div className="name" style={{ paddingTop: "20px", textAlign: "justify" }}>{idx.desc}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            
                        ))
                    }
                </Swiper>

                    </div>
                </div>
            </div>
            
            <CardModal 
                show={modalShow}
                onHide={() => setModalShow(false)} 
            />
        </section>
    );
}

export default PerkhidmatanKami;