

import img1 from '../images/perkhidmatan/infaq.png'
import img2 from '../images/perkhidmatan/iftar.png'
import img3 from '../images/perkhidmatan/waqaf.png'
import img4 from '../images/perkhidmatan/pengurusan.png'
import img5 from '../images/perkhidmatan/khairat.png'
import img6 from '../images/perkhidmatan/aqiqah.png'
import img7 from '../images/perkhidmatan/qurban.png'

const dataPerkhidmatan = [
    {
        id: 1,
        img: img1,
        title: 'Infaq',
        desc: 'Infaq dalam talian memudahkan masyarakat berinfaq dengan telus dan yakin kepada institusi memerlukan e.g. surau, madrasah & rumah anak yatim secara one-off atau berkala.',
    },
    {
        id: 2,
        img: img2,
        title: 'Infaq Iftar',
        desc: 'Modul istimewa yang dibangunkan khusus untuk menampung perbelanjaan iftar semasa Ramadhan. Penginfaq boleh menaja kesemua atau sebahagian daripada jumlah harian kos penyediaan makanan iftar bagi institusi yang terlibat.',
    },
    {
        id: 3,
        img: img3,
        title: 'Waqaf',
        desc: 'Waqaf membantu umat Islam menjalankan ibadat waqaf dengan memilih sama ada waqaf am ataupun waqaf khas.',
    },
    {
        id: 4,
        img: img4,
        title: 'Pengurusan Masjid',
        desc: 'Pengurusan masjid yang melibatkan pengurusan sumber manusia, hebahan aktiviti & acara, aset & inventori, dan kewangan & perolehan',
    },
    {
        id: 5,
        img: img5,
        title: 'Pengurusan Kariah',
        desc: 'Pengurusan ini melibatkan proses pendaftaran kariah dan pengurusan khairat yang merekodkan penerima manfaat, jenis bantuan, dan kesan program.',
    },
    {
        id: 6,
        img: img6,
        title: 'Aqiqah',
        desc: 'Afdhal untuk mengaqiqahkan anak pada hari ke-7 kelahiran. Memudahkan dan mempercepatkan pembelian haiwan aqiqah dengan bayaran dalam talian. Ibubapa akan menerima status dan notifikasi selepas penyembelihan selesai.',
    },
    {
        id: 7,
        img: img7,
        title: 'Qurban',
        desc: 'Ibadat qurban yang dituntut ketika Aidiladha dapat dijalankan dengan mudah dan lancar apabila pembelian haiwan qurban sama ada seekor atau sebahagian dilakukan dalam talian. Daging qurban akan diagihkan kepada mereka yang berhak dan penduduk setempat.',
    },

   
]

export default dataPerkhidmatan;