import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ScrollToTop from "./ScrollToTop";

import 'mdb-react-ui-kit';
import 'react-bootstrap';
import '../src/assets/binasea.css';
import '../src/assets/font-awesome.css';
import './App.scss';



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <BrowserRouter>
            <ScrollToTop />
            <App />
        </BrowserRouter>
    </React.Fragment>
);

