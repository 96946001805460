import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import CardModal from '../layouts/CardModal';
import DefaultImage from "../../assets/images/logo/logo_dark.png";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';

ListMasjidSection.propTypes = {
    data: PropTypes.array,
};

function ListMasjidSection(props) {
    const [modalShow, setModalShow]             = useState(false);
    const [senaraiMasjid, setSenaraiMasjid]     = useState([]);
    const [page, setPage]                       = useState(1);
    const [pageCount, setPageCount]             = useState(1);
    const navigate                              = useNavigate();
    const itemsPerPage                          = 12;

    const fetchMasjidList = async (page) => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        try {
            const response = await fetch(`https://api.al-jariyah.com/api/portal/senarai-institusi?page=${page}&limit=${itemsPerPage}`, requestOptions);
            const result = await response.json();
            setSenaraiMasjid(result.data.row);
            setPageCount(Math.ceil(result.data.total / itemsPerPage));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchMasjidList(page);
    }, [page]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <section className="tf-section tf-hot-pick tf-filter">
            <div className="tf-container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="tf-heading style-5">
                            <h4 className="heading">Hot Picks</h4>
                        </div>

                        <div className="tf-create-wrap col-md-12" style={{ display: 'flex', flexWrap: 'wrap', marginLeft:"0px" }}>
                            {senaraiMasjid.map((masjid, index) => (
                                <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber " style={{ flex: '1 0 25%' }}>
                                    <div className="sc-product style2" style={{ display: 'flex', flexDirection: 'column', height: '97%' }}>
                                        <div className="features" style={{ flex: '1' }}>
                                            <div className="product-media" style={{ width: '100%', height: '300px', overflow: 'hidden' }}>
                                                <Link to={`/institusi/${masjid.organizationCode}`} state={masjid}>
                                                    <img
                                                        src={masjid.organizationImage ? masjid.organizationImage : DefaultImage}
                                                        alt={DefaultImage}
                                                        onError={(e) => {
                                                            e.target.onError = null;
                                                            e.target.src = DefaultImage;
                                                        }}
                                                        style={{ width: '100%', height: '100%', objectFit: 'fit' }}
                                                    />
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="top" style={{ display: 'flex', flexDirection: 'column', height: '28%', paddingTop: '30px' }}>
                                            <Link to={`/institusi/${masjid.organizationCode}`} state={masjid} className="tag">{masjid.organizationName}</Link>
                                        </div>

                                        <div className="bottom-style2">
                                            <div className="product-button">
                                                <button className="tf-button" onClick={() => navigate(`/institusi/${masjid.organizationCode}`, { state: masjid })}>Maklumat Lanjut</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Pagination Component */}
                        <Pagination
                            count={pageCount}
                            page={page}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            className="pagination"
                            style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                        />
                    </div>
                </div>
            </div>

            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>
    );
}

export default ListMasjidSection;
