import React , {useState} from 'react';
import icon1 from '../assets/images/pintuUtama.png'
import icon2 from '../assets/images/platformBerpusat.png'
import icon3 from '../assets/images/pengurusan.png'
import icon4 from '../assets/images/analisa.png'
import icon5 from '../assets/images/realTime.png'
import icon6 from '../assets/images/kos.png'
import icon7 from '../assets/images/integrasi.png'

import { Link } from 'react-router-dom';

MengenaiAJ.propTypes = {
    
};

function MengenaiAJ(props) {
    const [dataHelp] = useState([
        {
            id: 1,
            icon: icon1,
            title: 'Pintu Utama',
            text: 'Semua perkhidmatan berkaitan Islam dengan organisasi berdaftar.',
            link: '#'
        },
        {
            id: 2,
            icon: icon2,
            title: 'Platform Berpusat',
            text: 'Fleksibel untuk orang awam dan organisasi berdaftar menggunakan platform Al-Jariyah.',
            link: '#'
        },
        {
            id: 3,
            icon: icon3,
            title: 'Pengurusan',
            text: 'Platform pengurusan setempat dan penambahbaikan dalam perkhidmatan.',
            link: '#'
        },
        {
            id: 4,
            icon: icon5,
            title: 'Masa Nyata (Real Time)',
            text: 'Maklumat mengenai penggunaan dan perkhidmatan yang disediakan.',
            link: '#'
        },
        {
            id: 5,
            icon: icon4,
            title: 'Analisa',
            text: 'Pengurusan pelaporan dan papan pemuka yang tepat.',
            link: '#'
        },
        {
            id: 6,
            icon: icon6,
            title: 'Kos Efektif',
            text: 'Untuk semua pihak berkepentingan dalam penyediaan platform digital.',
            link: '#'
        },
        {
            id: 7,
            icon: icon7,
            title: 'Integrasi Mudah',
            text: 'Dengan sistem utama lain sedia ada jika diperlukan.',
            link: '#'
        },
    ])
    return (
        <div>
            <section className="tf-page-title style-2">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">

                            <ul className="breadcrumbs">
                                <li><Link to="/">Home</Link></li>
                                <li>Mengenai Al-Jariyah</li>
                            </ul>
                   
                        </div>
                    </div>
                </div>                    
            </section>
                
            <section className="tf-help-center">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="tf-heading style-5">
                                <h4 className="heading">Mengenai Al-Jariyah</h4>
                                <p className="sub-heading">Al-Jariyah merupakan gerbang utama ke semua perkhidmatan berkaitan Islam yang ditawarkan oleh institusi berdaftar di bawah satu platform. </p>
                            </div>

                            {/* <form action="#" className="help-center-form">
                                <input type="text" placeholder="Enter Question..." required="" />
                                <button><i className="fas fa-search"></i></button>
                            </form> */}


                            <div className="tf-create-wrap">

                                {
                                    dataHelp.map(idx => (
                                        <div key={idx.id} className="tf-create style-2" style={{cursor: 'default', pointerEvents: 'none'}}>
                                            <div className="icon">
                                                <img src={idx.icon} alt="AlJariyah" style={{borderRadius:"20px"}}/>
                                            </div>
                                            <h6 className="title">{idx.title}</h6>
                                            <p className="content">{idx.text}</p>
                                            {/* <div className="button">
                                                <Link to={idx.link} className="tf-button">View more<i className="fal fa-long-arrow-right"></i></Link>
                                            </div> */}
                                        </div>
                                    ))
                                }
                            
                               
        
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default MengenaiAJ;