
import {React , useEffect} from 'react';
import AOS from 'aos';
import {BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import ItemDetailsid1 from './pages/InstitusiDetaisl';
import Receipt from './pages/Receipt';


import routes from './pages';
import Page404 from './pages/404';

import '../src/assets/binasea.css';
import '../src/assets/font-awesome.css';
import 'mdb-react-ui-kit';
import 'react-bootstrap';



function App() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        }); 
      }, []);

    return (
        <>

            <Header />

            <Routes>
                    {routes.map((data, idx) => (
                    <Route key={idx} path={data.path} element={data.component} exact />
                    ))}
                    <Route path="/institusi/:slug" element={<ItemDetailsid1 />} />
                    <Route path="/bayaran/resit" element={<Receipt />} />
                <Route path='*' element={<Page404 />} />
            </Routes>

            <Footer />
        </>
    );
}

export default App;
