import React from 'react';
import PageTitle from '../components/pagetitle/PageTitle';


BlogDetails01.propTypes = {
    
};

function BlogDetails01(props) {
    return (
        <div>

            <PageTitle sub='Blog' title='Kenyataan Penafian Al-Jariyah' />
            
            <section className="tf-blog-detail">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="detail-inner">
                                
                                <div className="content-inner" style={{textAlign:"justify"}}>
                                    {/* <h6 className="title">What Is The Most Fun Thing To Become A Designer?</h6> */}
                                    <p >Sebagai makluman, Platform Al-Jariyah bukanlah agensi pemungut Zakat atau Pemungut Dana. Al-Jariyah adalah merupakan penyedia perkhidmatan digital yang menghubungkan masyarakat umum sebagai pengguna dengan Institusi Islam bagi membolehkan Institusi Islam membuat pengumpulan sumbangan infaq dan waqaf secara dalam talian.</p><br/>
                                    <p>Al-Jariyah tidak bertanggungjawab atas pelaksanaan atau maklumat yang disediakan oleh Institusi terhadap sebarang iklan dan kempen pengumpulan dana. Al-Jariyah dengan ini melepaskan semua tanggungjawab dalam hal tersebut selagi tidak melanggar sebarang undang-undang negara Malaysia.</p>
                                </div>
                                
                                <div className="content-inner" style={{textAlign:"justify"}}>
                                    <p>Semua maklumat yang diiklan sebagai kempen pengumpulan dana di dalam Platform Al-Jariyah adalah merupakan maklumat dari pihak Institusi sendiri. Al-Jariyah tidak bertanggungjawab dan tidak menjamin ketepatan atau kebenaran dari kandungan yang dibuat oleh Institusi yang melaksanakan kempen pungutan dana.</p><br/>
                                    <p>Maklumat-maklumat yang terkandung di ruang iklan dan kempen Al-Jariyah bagi sesebuah Institusi berkaitan adalah di bawah risiko Institusi sendiri.</p>
                                </div>
                                <div className="content-inner" style={{textAlign:"justify"}}>
                                    <p>Al-Jariyah tidak menyokong atau menyelenggara sebarang iklan atau kempen yang dianjurkan oleh sesebuah Institusi, kecuali berlaku perjanjian bertulis secara rasmi yang dimeterai oleh pihak-pihak yang terlibat. Al-Jariyah dengan tegas menolak kewajipan atau tanggungjawab atas kegagalan setiap iklan atau kempen atau jumlah sumbangan yang mungkin tidak memenuhi ketetapan Institusi berkaitan.</p>
                                </div>
                                
                            </div>                        
                        </div>                                     
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default BlogDetails01;