import React, { useState, useEffect } from 'react';
import PageTitle from '../components/pagetitle/PageTitle5';
import { useLocation, useNavigate,useSearchParams } from 'react-router-dom';
import moment from 'moment';
import DefaultImage from "../assets/images/kempenDefault.jpg"

import avt1 from '../assets/images/blog/author.png';

MaklumatKempen.propTypes = {};

function MaklumatKempen(props) {
  const navigate = useNavigate();
  const state = useLocation().state;
  const [searchParams] = useSearchParams()
  console.log ("useSearchParams", searchParams.get("orgCode"))

  const [content, set_content]          = useState('');
  const [target, set_target]            = useState('');
  const [collection, set_collection]    = useState('');
  const [image, set_image]              = useState('');
  const [title, set_title]              = useState('');
  const [create_date, set_create_date]  = useState('');
  const [org_name, set_org_name]        = useState('');
  const [org_code, set_org_code]        = useState("");
  const [campaign_code,set_campaign_code] = useState("");
  const [org_Id,set_org_Id]             = useState(null);

  // const [kempen_ID,set_kempen_ID]       = useState(state.campaignCode);

  const [kempen_ID, set_kempen_ID] = useState(searchParams.get("campaignId"));

  const [payor_name,set_payor_name]     = useState('');
  const [payor_email,set_payor_email]   = useState('');
  const [payor_phone,set_payor_phone]   = useState('');
  const [amount,set_amount]             = useState('');


  const orgCode = searchParams.get("orgCode");
  const campaignId = searchParams.get("campaignId");

  

  const DETAILS_MASJID = async () => {
    const requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      
      await fetch(`https://api.al-jariyah.com/api/portal/senarai-institusi/${orgCode}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            console.log("Details Institusi",result)
            let { 
                organizationCode
            } = result.data[0]
            
            set_org_code(organizationCode)
        })
        
        .catch((error) => console.error(error));
}

  const KEMPEN_DETAILS = async () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    await fetch(
      `https://cp-api.al-jariyah.com/admin/getKempenDetails?campaignId=${campaignId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        let {
          campaignTitle,
          campaignContent,
          campaignImage,
          campaignTarget,
          campaignDeadline,
          organizationName,
          organizationId,
          tabungCollection,
          campaignCode
        } = result.data;
        set_content(campaignContent);
        set_target(campaignTarget);
        set_collection(tabungCollection);
        set_image(campaignImage);
        set_title(campaignTitle);
        set_create_date(campaignDeadline);
        set_org_name(organizationName);
        set_org_Id(organizationId);
        set_campaign_code(campaignCode);

        console.log("Org ID",organizationId);
      })
      .catch((error) => console.error(error));
  };


  const CREATE_TRANSACTION = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        campaign_code: campaign_code,
        org_id: org_Id,
        payer_name: payor_name,
        payer_email: payor_email,
        payer_phone: payor_phone,
        payer_amount: amount,
        payment_channel: "Online Banking"
      }),
      redirect: 'follow',
    };
  
    await fetch(
      'https://cp-api.al-jariyah.com/admin/kempen/create-transaction',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('Transaction Created:', result);
        if(result.status_code === 200){
          window.open(result.data.URL)
        }
      })
      .catch((error) => console.error('Error:', error));
  };
  

  useEffect(() => {
    KEMPEN_DETAILS();
    DETAILS_MASJID()
  }, []);


  return (
    <div>
      <PageTitle sub1="Senarai Institusi" sub2="Maklumat Institusi" title="Kempen Institusi" />

      <section className="tf-blog-detail py-5">
        <div className="container">
          <div className="row">
            {/* Left Side: Campaign Details */}
            <div className="col-md-8">
              <div className="detail-inner bg-light p-4 rounded">
                <div className="content-top">
                  <h4 className="title mb-4">{title}</h4>
                  <div className="author-infor d-flex align-items-center" >
                    <div className="image me-3">
                      <img src={avt1} alt="AlJariyah" className="rounded-circle" width="50" />
                    </div>
                    <div>
                      <span>by</span>
                      <span className="mx-2">{org_name}</span>
                      <span className="mx-2">|</span>
                      <span>{moment(create_date).format('DD MMM, YYYY')}</span>
                    </div>
                  </div>
                </div>
                <div className="image my-4" style={{ border: "double", width: "100%", height: "300px", overflow: "hidden" }}>
                    <img
                        src={image ? image : DefaultImage } 
                        alt={DefaultImage} 
                        onError={(e) =>{
                            e.target.onError=null
                            e.target.src = DefaultImage
                        }}
                        className="img-fluid rounded" style={{ width: "100%", height: "100%", objectFit: "cover" }} 
                    />
                </div>
                <div className="content-inner mb-5" dangerouslySetInnerHTML={{ __html: content }}></div>
              </div>
            </div>

            {/* Right Side: Donation Form */}
            <div className="col-md-4">
              <div className="donation-section bg-white p-4 rounded shadow-sm">
                <h5 className="mb-4">Sumbang Sekarang</h5>
                <form onSubmit={(e) => {
                            e.preventDefault();
                            CREATE_TRANSACTION();
                            }}
                      style={{width:"auto"}}>
                  <div className="mb-3">
                    <label className="form-label">Nama Penyumbang</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={payor_name}
                      onChange={e => set_payor_name(e.target.value)}
                      placeholder="Cth: Aiman Bin Yazid"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">E-mel</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={payor_email}
                      onChange={e => set_payor_email(e.target.value)}
                      placeholder="Cth: aiman123@gmail.com"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">No. Telefon</label>
                    <input
                      type="tel"
                      name="phone"
                      className="form-control"
                      value={payor_phone}
                      onChange={e => set_payor_phone(e.target.value)}
                      placeholder="Cth: 0123456789"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Amaun Sumbangan</label>
                    <div className="input-group">
                      <span className="input-group-text">RM</span>
                      <input
                        type="number"
                        name="amount"
                        className="form-control"
                        value={amount}
                        onChange={e => set_amount(e.target.value)}
                        placeholder="Cth: 1000"
                        required
                      />
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary w-100" style={{backgroundColor:"teal", fontSize:"15px", fontWeight:"bold"}} >
                    Sumbang Sekarang
                  </button>
                </form>
              </div>
              <div className="progress my-3" style={{ height: '20px', backgroundColor:"" }}>
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ height: '20px', width: `${(collection / target) * 100}%` }}
                  aria-valuenow={(collection / target) * 100}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <div className="d-flex justify-content-between" style={{ fontWeight: 'bold' }}>
                <span>{`RM ${collection} (${Math.min((collection / target) * 100, 100).toFixed(2)}%)`}</span>
                <span>{`RM ${target}`}</span>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MaklumatKempen;

