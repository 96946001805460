import React from 'react';
import img1 from '../assets/images/mockup_TranparentBG.png'
import { Link } from 'react-router-dom';

CallToAction.propTypes = {
    
};

function CallToAction(props) {
    return (
        <div className='page-explore'>

            <section className="tf-baner-live-auction style-2">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-liver-auction-wrap">
                                <div className="content">
                                    <div className="heading">
                                        <h2 className="title">Jom Sertai Kami</h2>
                                        <span className="label"><Link to='https://cpdemo-kariah.al-jariyah.com/register' target='_blank' style={{color:"white"}}>Sertai Kami</Link></span>
                                    </div>
                                    {/* <div className="price">
                                        <span className="icon"><i className="fab fa-ethereum"></i></span>
                                        <span>5 ETH</span>
                                    </div> */}
                                    <p className="sub-heading">sebagai perintis Al-Jariyah</p>
                                </div>
                                <div className="image">
                                    <img src={img1} alt="AlJariyah" className="img1" style={{width:"45%"}}/>
                                    {/* <img src={img2} alt="AlJariyah" className="img2" style={{width:"100px"}}/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
        </div>
    );
}

export default CallToAction;