import React,{useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle5';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import avt1 from '../assets/images/blog/author.png'

BlogDetails01.propTypes = {
    
};

function BlogDetails01(props) {
    //const navigate = useNavigate()
    const state = useLocation().state

    const [content, set_content]                                = useState("")
    const [desc, set_desc]                                      = useState("")
    const [image, set_image]                                    = useState("")
    const [title, set_title]                                    = useState("")
    const [create_date, set_create_date]                        = useState("")

    const [org_code, set_org_code]                              = useState("")


    const [nama_masjid, set_nama_masjid]                        = useState("")


    const DETAILS_MASJID = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
          };
          
          await fetch(`https://api.al-jariyah.com/api/portal/senarai-institusi/${state.ORGANIZATION_ID}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                let { 
                    organizationName, 
                    organizationCode,
                } = result.data[0]
                set_nama_masjid(organizationName)
                set_org_code(organizationCode)
            })
            
            .catch((error) => console.error(error));
    }




    const LIST_HEBAHAN = async () => {
        
        const requestOptions = {
            method: "GET",
            redirect: "follow"
          };
          
          
          await fetch(`https://cp-api.al-jariyah.com/admin/getHebahanDetails?hebId=${state.HEB_ID}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                let { 
                    HEB_CONTENT, 
                    HEB_DESCRIPTION, 
                    HEB_IMAGE,
                    HEB_TITLE,
                    HEB_CREATED_DATE,
                } = result.data[0]
                set_content(HEB_CONTENT)
                set_desc(HEB_DESCRIPTION)
                set_image(HEB_IMAGE)
                set_title(HEB_TITLE)
                set_create_date(HEB_CREATED_DATE)
                
            })
            
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        LIST_HEBAHAN()
        DETAILS_MASJID()
    }, [])

    return (
        <div>

            <PageTitle sub1="Senarai Institusi" sub2="Maklumat Institusi" title="Hebahan Institusi" />
            
            <section className="tf-blog-detail">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="detail-inner">
                                <div className="content-top">
                                    <h4 className="title">{title} </h4>
                                    <div className="author-infor">
                                        <div className="image">
                                            <img src={avt1} alt="AlJariyah" />
                                        </div>
                                        <span>by</span>
                                        <span className="mr9">{nama_masjid}</span>
                                        {/* <Link to="#">{nama_masjid}</Link> */}
                                        <span className="mr9">|</span>
                                        <span>{moment(create_date).format("DD MMM, YYYY")}</span>

                                    </div>
                                </div>
                                <div className="image my-4" style={{ border: "double", width: "100%", height: "350px", overflow: "hidden" }}>
                                    <img src={image} alt="AlJariyah" className="img-fluid rounded" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                </div>
                                <div className="content-inner" dangerouslySetInnerHTML={{ __html:content}}>
                                    {/* <h6 className="title">What Is The Most Fun Thing To Become A Designer?</h6> */}
                                    {/* <p>{content}</p><br/> */}
                                    {/* <p>The agreement was inked by Mr. Mohamed Hazli bin Mohamed Hussain, CEO of DagangTEK Sdn Bhd and Mr. Aizul Hisham Bin Mohd Dom the Manager of ANF Trade & Communications. This signing was witnessed by Mr. Zahari Azar Zainudin, Senior Vice President of DagangTEK Sdn Bhd. The session was also attended by representatives from DagangTEK Sdn Bhd.</p> */}
                                </div>
                                {/* <div className="image">
                                    <img src={img4} alt="AlJariyah" />
                                </div> */}
                                {/* <div className="image style-2">
                                    <img className="mr20" src={img2} alt="AlJariyah" />
                                    <img src={img3} alt="AlJariyah" />
                                </div> */}
                                {/* <div className="content-inner">
                                    <p>This collaboration agreement marks a significant step forward in empowering religious institutions across the country. By combining strengths and expertise, both parties are confident in achieving outstanding results and contributing positively to the communities they serve.</p><br/>
                                    <p>Both organizations believed digitalization of religious institution administrations will be a driving force in revolutionizing the way they operate and manage their resources, ultimately benefiting not only the institutions themselves but also the whole community in general.</p>
                                </div>
                                <div className="content-inner">
                                    <p>This momentous ceremony took place at DagangTEK’s headquarter office located in IOI Resort City, Putrajaya.</p>
                                </div> */}
                                
                            </div>                        
                        </div>                                     
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default BlogDetails01;